:root {
  --bg: #3646CD;
  --bg-text: #fff;
  --text: var(--text);
  ;
  --bs-navbar-toggler-icon-bg: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path stroke="rgba(255, 255,255, 1)" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M4 7h22M4 15h22M4 23h22"/></svg>');
}

.bg-app {
  background-color: var(--bg) !important;
}

.gray-bg {
  background-color: #F6F6F6;
  padding-top: 24px;
  padding-bottom: 24px;
}

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #626262;
}

.container-fuild {
  padding-left: 12px;
  padding-right: 12px;
}

.navbar {
  background-color: var(--bg);
  color: var(--bg-text);
}

.navbar-brand {
  max-width: 40px;
  padding: 0;
}

.navbar-brand img {
  width: 100%;
}

.navbar-toggler, .navbar-toggler:focus {
  border: 0 !important;
  outline: 0 !important;
  box-shadow: unset !important;
}

.navbar-toggler .navbar-toggler-icon {
  background: none;
  color: var(--bg-text);
}

.app-nav-link {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  padding: 12px 0;
  border-top: 1px solid #ccc;
  font-size: 22px;
}

.app-nav-link span {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}

.offcanvas-body a {
  color: var(--text);
}

.offcanvas-title {
  color: var(--text);
  font-size: 24px;
}

.top-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 250px;
  /* background: linear-gradient(180deg, rgba(255, 255, 255, 0.33) 0%, #FFFFFF 100%); */
}

.top-box .logo {
  max-width: 100%;
}

.top-bg {
  background: url('./components/images/top-bg.svg') no-repeat var(--bg) bottom center;
  background-position: center 8px;
}

.app-main-card {
  height: 100%;
  background-color: #fff;
  border-radius: 32px 32px 0px 0px;
  position: relative;
  z-index: 1;
  padding-top: 48px;
}

.onboard-card {
  margin-top: -30px;
  height: calc(100vh - 220px);
  min-height: 300px;
  overflow: auto;
}

.app-title {
  font-family: Roboto;
  font-weight: 700;
  line-height: 28px;
  color: var(--bg);
  letter-spacing: 0.5px;
  margin-bottom: 24px;
  font-size: 24px;
}

.app-title-2 {
  font-family: Roboto;
  font-weight: 700;
  line-height: 28px;
  color: var(--text);
  ;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
  font-size: 24px;
}

.sub-title {
  font-size: 15px;
  margin: 0;
}

.title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
}

.title-box .app-title, .title-box .app-title-2 {
  margin: 0;
}

.title-box .link {
  font-size: 12px;
  font-family: roboto;
  background: var(--bg);
  color: var(--bg-text);
  padding: 5px 8px;
  text-transform: uppercase;
  border-radius: 4px;
}

.input-group .input-group-text {
  position: absolute;
  z-index: 9;
  background: transparent;
  border: 0;
  font-size: 16px;
  color: #626262;
  height: 100%;
}

.input-group .form-control {
  padding-left: 42px;
  height: 56px;
  border-radius: 8px !important;
  background: #F4F4F4;
  border: 0;
  font-size: 14px;
  line-height: 20px;
  color: #626262;
  font-family: Roboto;
}

.over-lable {
  position: absolute;
  top: -7px;
  z-index: 99;
  font-size: 10px;
  left: 7px;
  font-weight: bold;
  /* padding: 0px 3px;
  border-radius: 3px;
  background: #fff; */
}

.input-group:focus-within .over-lable {
  background: #fff;
  padding: 0px 4px;
  top: -11px;
}

.col-12:focus-within .position-relative .over-lable {
  background: #fff;
  padding: 0px 4px;
  top: -10px;
}

.search {
  border-radius: 12px !important;
}

.search .form-control {
  font-size: 20px;
  padding-left: 48px;
}

.search .input-group-tex {
  font-size: 23px;
}

.form-select {
  padding-left: 14px !important;
  height: 56px;
  border-radius: 8px !important;
  background-color: #F4F4F4 !important;
  border: 0 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #626262 !important;
  font-family: Roboto !important;
}

.form-select:focus {
  background-color: #fff !important;
}

.btn.btn-app {
  background-color: var(--bg);
  color: var(--bg-text);
  font-family: Roboto;
  font-size: 14px;
  line-height: 20px;
  border-color: var(--bg);
  height: 56px;
  border-radius: 8px !important;
  width: 100%;
}

.btn.btn-app-active {
  background-color: var(--bg-text);
  color: var(--bg);
}

.btn.btn-app:hover {
  background-color: var(--bg-text);
  color: var(--bg);
}

.text-2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--bg);
}

.link {
  text-decoration: none;
  color: var(--bg);
}

/* Home Nav*/
.home-top {
  background-position: 56% -66px;
}

.home-top .top-box {
  justify-content: space-between;
}

.nav-trigger {
  display: flex;
  line-height: 0;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 28px;
  height: 28px;
  background: #4E5EEA;
  border-radius: 4px;
  padding: 8px 4px;
  font-size: 20px;
  cursor: pointer;
}

.profile {
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  font-size: 11px;
  overflow: hidden;
  background: #fff;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.profile img {
  width: 100%;
}

.app-alert-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(220, 53, 69, 15%);
  padding: 12px 28px;
  border-radius: 16px;
  margin-bottom: 24px;
}

.app-alert-icon {
  justify-content: center;
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 100%;
  background-color: #DC3545;
  margin-right: 12px;
  font-size: 21px;
}

.app-alert-icon svg {
  background: #fff;
  border-radius: 100%;
  fill: #DC3544;
}

.app-alert-box p {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  color: #DC3545;
  line-height: 16px;
}

.app-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00E9AB;
  height: 164px;
  border-radius: 19px;
  color: #fff;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 8px;
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
}

.grid-icon {
  justify-content: center;
  display: flex;
  align-items: center;
  width: 72px;
  height: 72px;
  background: rgba(18, 240, 181, 1);
  border-radius: 100%;
  font-size: 39px;
  position: relative;
  z-index: 2;
}

.app-grid p {
  width: 100%;
  color: #fff;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  margin: 0;
  position: relative;
  z-index: 2;
}

.grid-vector {
  display: flex;
  position: absolute;
  bottom: -39px;
  z-index: 0;
  right: 0;
}

.benif-section {
  padding-left: 6px;
  padding-right: 6px;
}

.benif-section .col-4 {
  padding-left: 6px;
  padding-right: 6px;
}

.app-benif-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 110px;
  border-radius: 10px;
  color: var(--text);
  ;
  flex-direction: column;
  gap: 8px;
  position: relative;
  overflow: hidden;
  margin-bottom: 16px;
  padding: 10px;
  cursor: pointer;
}

.benif-section .col-4:first-child .app-benif-grid {
  color: var(--bg-text);
  background-color: var(--bg);
}

.benif-circle {
  width: 64px;
  height: 64px;
  min-width: 64px;
  min-height: 64px;
  border-radius: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 35px;
  letter-spacing: 0.02em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E8E8E8;
  color: #BBBBBB;
}

.benif-section .col-4:first-child .benif-circle {
  color: var(--bg-text);
  background-color: #4958DF;
}

.app-benif-grid .text-2 {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  color: var(--text);
  ;
  font-size: 13px;
  text-align: center;
}

.app-benif-grid.active {
  color: var(--bg-text);
  background-color: var(--bg);
}

.app-benif-grid.active .benif-circle {
  color: var(--bg-text);
  background-color: #4958DF;
}

.ajaxLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: .25rem;
  -ms-flex-align: center;
  align-items: center;
  background: rgba(0, 0, 0, .3);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 50;
}

.back {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 30px;
  min-height: 30px;
  border-radius: 5px;
  color: var(--bg-text);
  font-size: 19px;
}

.menu-left {
  display: flex;
  gap: 10px;
}

.menu-right {
  display: flex;
  gap: 10px;
}

.app-card {
  overflow: hidden;
  padding: 20px 16px;
  border: 0 !important;
  background: #FFFFFF;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.05);
  border-radius: 12px !important;
  margin-bottom: 24px;
  min-height: 100px;
  user-select: none;
}

.card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.app-card .card-title, .app-card .card-text {
  width: calc(100% - 20px);
}

.app-card .card-body {
  padding: 0 !important;
}

.btn-app-card {
  display: flex !important;
  position: absolute;
  right: 0;
  top: 0;
  background: var(--bg) !important;
  color: var(--bg-text) !important;
  border-radius: 0px 0 0 0px !important;
  font-size: 15px !important;
  padding: 8.4px 9px !important;
}

.btn-app-card.edit {
  top: 33px;
  background-color: #5cb85c !important;
}

.btn-app-card.del {
  top: 67px;
  background-color: #e53434 !important;
}

.btn-app-card-ph {
  position: absolute;
  right: 0;
  top: 0;
}

.trans-card {
  cursor: pointer;
  background: rgb(54, 70, 205) !important;
  background: linear-gradient(147deg, rgb(54, 70, 205) 0%, rgb(94, 181, 228) 100%) !important;
  color: #fff !important;
  box-shadow: none
}

.trans-card:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
}

.trans-card .card-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.trans-card .card-title .badge {
  font-size: 10px;
  padding: 5px 6px;
}

.trans-card .card-text {
  width: 100%;
  color: #fff !important;
  padding: 2px 0;
}

.app-list .list-group-item {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  flex-wrap: wrap;
}

.app-list .list-group-item p {
  margin: 0;
  width: 50%;
  color: var(--text);
  ;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.app-list .list-group-item p:last-child {
  text-align: right;
}

.offlinePopup {
  display: flex;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  justify-content: center;
  align-items: center;
}

.offlinePopup .internetBox {
  text-align: center;
  font-size: 48px;
  border-radius: 8px;
  padding: 20px 20px;
  color: #dc3545;
  -webkit-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.2);
}

.internetBox p {
  margin-bottom: 0;
  color: #ababab;
}

.close {
  cursor: pointer;
}

.money-info-header {
  display: flex;
  justify-content: space-between;
  background: #eee;
  padding: 10px 13px;
  border-radius: 5px;
}

.money-info-header h5 {
  font-size: 13px;
  margin-bottom: 2px;
}

.money-info-header p {
  font-weight: bold;
  margin-bottom: 0px;
}

.money-info-header p span {
  color: var(--bg);
}

@media (min-width: 992px) {
  .mx-width {
    max-width: 720px !important;
  }
}

@media only screen and (max-width: 768px) {
  .top-box {
    height: 204px;
    padding-bottom: 30px;
  }

  .top-bg {
    background-position: 56% 8px;
  }

  /* Home Nav*/
  .home-top {
    background-position: 56% -66px;
  }

  .home-top .top-box {
    height: 96px;
  }

  .home-top .logo {
    width: 60px;
  }

  .onboard-card {
    height: calc(100vh - 66px);
  }
}

@media only screen and (max-width: 330px) {
  .app-grid {
    height: 150px;
  }

  .app-grid p {
    font-size: 16px;
  }

  .grid-vector {
    bottom: -50px;
  }

  .app-benif-grid {
    height: 100px;
  }

  .benif-circle {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    font-size: 20px;
  }

  .app-benif-grid .text-2 {
    font-size: 11px;
  }
}